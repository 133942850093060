import '@babel/polyfill';
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import './plugins/vuetify';
import './plugins/vee-validate';
import App from './App.vue';
import router from './router';
import store from '@/store';
import VueI18n from 'vue-i18n';
import {defaultLocale, messages} from '@/i18n';
import VueSwal from 'vue-swal';
import VueNoty from 'vuejs-noty';
import VueCryptojs from 'vue-cryptojs';
import VueApexCharts from 'vue-apexcharts';
import KProgress from 'k-progress';
import './registerServiceWorker';
import './assets/fonts/OpenSans/stylesheet.css';
import 'vuejs-noty/dist/vuejs-noty.css';
// import './assets/css/app.css';
// import './assets/css/stake.min.css';
import './assets/font-awesome/css/fontawesome-all.css';
import './assets/js/tradingview.js';

import * as Fingerprint2 from 'fingerprintjs2';
Vue.prototype.$fingerprint = Fingerprint2;

Vue.mixin({
  methods: {
	  
    stamp(sec=true) {
      const stamp = (new Date()).getTime();
      return (sec) ? Math.round(stamp / 1000) : stamp;
    },
    stamp2date(stamp, full=true) {
      if (stamp === null) { return 'n/a'; }
      const d = new Date(stamp * 1000);
      return (full) ? d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear() + ' ' + d.getHours() + ':' + ('0' + d.getMinutes()).slice(-2) : d.getHours() + ':' + ('0' + d.getMinutes()).slice(-2);
    },
    getPos(el) {
      if (el === undefined) { return false; }
      el = document.querySelector(el);
      return (el !== null) ? { el, pos: el.getBoundingClientRect() } : false;
    },
    // scrollToElem(el) {
    //   let top = (el === undefined) ? 0 : parseInt(fn.getPos(el).top);
    //   window.scrollTo({top: top, left: 0, behavior: 'smooth'});
    // },
    scrollTo(el, top=true) {
      if (!(el = document.querySelector(el) || false)) { return false; }
      el.scrollTo({ top: (top) ? 0 : el.scrollHeight, left: 0, behavior: 'smooth' });
    },
    fromHex(h) {
      let s = '';
      for (let i = 0; i < h.length; i += 2) {
        s += String.fromCharCode(parseInt(h.substr(i,2),16));
      }
      return decodeURIComponent(escape(s));
    },
    getElem(sel) {
      if (sel === undefined || sel === null || typeof sel !== 'string') { return false; }
      return document.querySelector(sel) || false;
    },
    copyToClipboard(text) {
      if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        const textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand('copy');
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
  },
});

Vue.config.productionTip = false;

Vue.use(VueI18n);
const i18n = new VueI18n({
  messages,
  locale: localStorage.getItem("lang") || defaultLocale,
  fallbackLocale: defaultLocale,
});
Vue.use(VueSwal);
Vue.use(VueNoty);
Vue.use(VueCryptojs);
Vue.component('apexchart', VueApexCharts);
Vue.component('k-progress', KProgress);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
