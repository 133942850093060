








import { Component, Vue } from 'vue-property-decorator';
import {commitSetUserFp} from '@/store/main/mutations';

@Component
export default class App extends Vue {

  public getFp() {
    // @ts-ignore
    this.$fingerprint.get((components) => {
      // @ts-ignore
      const fingerprint = this.$fingerprint.x64hash128(components.map((pair) => pair.value).join(), 31);
      commitSetUserFp(this.$store, fingerprint);
    });
  }

  public async created() {
    this.getFp();
  }
}
