import { api } from '@/api';
import router from '@/router';
import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
    commitSetUser,
} from './mutations';
import { MainState } from './state';

type MainContext = ActionContext<MainState, State>;

export const actions = {
    async actionGetUser(context: MainContext) {
        commitSetUser(context, {
            ref: '',
            un: localStorage.un,
            hash: localStorage.hash,
            uid: localStorage.uid,
            isHasPin: localStorage.isHasPin,
            pin: localStorage.pin,
        });
    },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchGetUser = dispatch(actions.actionGetUser);
