export enum Locales {
  EN = 'en',
  RU = 'ru',
  FA = 'fa',
  AR = 'ar',
  UK = 'uk',
}

export const LOCALES = [
  { value: Locales.EN, caption: 'English' },
  { value: Locales.RU, caption: 'Русский' },
  { value: Locales.FA, caption: 'زبان فارسي' },
  { value: Locales.AR, caption: 'Հայոց լեզու' },
  { value: Locales.UK, caption: 'Украинский' },
];
