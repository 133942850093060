import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('./views/Home/Home.vue'),
    },
    {
      path: '/',
      component: () => import('./views/Base.vue'),
      children: [
        {
          path: '/swap',
          component: () => import('./views/Swap/Swap.vue'),
        },
        {
          path: '/stake',
          component: () => import('./views/Stake/Stake.vue'),
        },
        {
          path: '/wallet',
          component: () => import('./views/Wallet/Wallet.vue'),
          props: true,
        },
        {
          path: '/profile',
          component: () => import('./views/Profile/Profile.vue'),
        },
        {
          path: '/faq',
          component: () => import('./views/Faq/Faq.vue'),
        },
        {
          path: '/partner',
          component: () => import('./views/Partner/Partner.vue'),
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
