import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    user: (state: MainState) => state.user,
    user_fp: (state: MainState) => state.user_fp,
    modalShow: (state: MainState) => state.modalShow,
    symbol: (state: MainState) => state.symbol,
    coins: (state: MainState) => state.coins,
};

const {read} = getStoreAccessors<MainState, State>('');

export const readUser = read(getters.user);
export const readUserFp = read(getters.user_fp);
export const readModalShow = read(getters.modalShow);
export const readSymbol = read(getters.symbol);
export const readCoins = read(getters.coins);
