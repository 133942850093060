import {ICoin, IUser} from '@/interfaces';
import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';


export const mutations = {
    setUser(state: MainState, payload: IUser) {
        state.user = payload;
    },
    setUserFp(state: MainState, payload: any) {
        state.user_fp = payload;
    },
    toggleModal(state: MainState, payload: string) {
        state.modalShow = payload;
    },
    setSymbol(state: MainState, payload: string) {
        state.symbol = payload;
    },
    setCoins(state: MainState, payload: ICoin[]) {
        state.coins = payload;
    },
};

const {commit} = getStoreAccessors<MainState | any, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUserFp = commit(mutations.setUserFp);
export const commitToggleModal = commit(mutations.toggleModal);
export const commitSetSymbol = commit(mutations.setSymbol);
export const commitSetCoins = commit(mutations.setCoins);
