import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { MainState } from './state';

const defaultState: MainState = {
  user: {
    un: '',
    ref: '',
    hash: '',
    pin: 0,
    uid: 0,
    isHasPin: false,
  },
  user_fp: null,
  modalShow: '',
  symbol: 'usdt',
  coins: [],
  API_URL: 'https://myexchange.ai/api'
};

export const mainModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
